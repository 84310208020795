<template>
  <el-container>
    <el-header height="210">
      <!--操作的下拉框-->
      <el-select
        @change="selectProvince"
        v-model="queryInfo.region"
        placeholder="请选择省份"
        style="margin-bottom: 25px; margin-left: 5px"
      >
        <el-option
          v-for="(item, index) in provinceData"
          :key="index"
          :label="item.label"
          :value="item.label"
        ></el-option>
      </el-select>
      <el-input
        v-model="queryInfo.score"
        @blur="getUserInfo"
        oninput="value=value.replace(/[^\d]/g,'')"
        placeholder="请输入分数"
        prefix-icon="el-icon-search"
        style="margin-left: 5px; width: 13%"
      ></el-input>
      <el-input
        v-model="queryInfo.number"
        @blur="getUserInfo"
        oninput="value=value.replace(/[^\d]/g,'')"
        placeholder="请输入手机号查询考试状态"
        prefix-icon="el-icon-search"
        style="margin-left: 5px; width: 13%"
      >
      </el-input>
      <el-select
        @change="examChange"
        v-model="queryInfo.examId"
        placeholder="请选择题目"
        style="margin-bottom: 25px; margin-left: 5px"
      >
        <el-option
          v-for="(item, index) in allExamInfo"
          :key="index"
          :label="item.examName"
          :value="item.examId"
        >
        </el-option>
      </el-select>
      <el-button
        @click="exportExcelClick"
        :loading="excelBtn.excelBtnLoading"
        style="margin-left: 10px"
        type="primary"
      >
        {{ excelBtn.btnText }}
      </el-button>
    </el-header>

    <el-main>
      <el-table
        ref="questionTable"
        highlight-current-row
        v-loading="loading"
        :border="true"
        :data="examRecords"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column
          align="center"
          prop="teachName"
          label="考试名称"
        ></el-table-column>

        <el-table-column
          align="center"
          prop="examTime"
          label="参考时间"
        ></el-table-column>

        <el-table-column
          align="center"
          prop="name"
          label="参考人"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="region"
          label="省级"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="phone"
          label="手机号"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="logicScore"
          label="逻辑题得分"
        ></el-table-column>
        <el-table-column align="center" label="总分">
          <template slot-scope="scope">
            <span>{{
              scope.row.totalScore === null ? "未批阅" : scope.row.totalScore
            }}</span>
          </template>
        </el-table-column>
      </el-table>

      <!--分页-->
      <el-pagination
        style="margin-top: 25px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[10, 20, 30, 50, total]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-main>
  </el-container>
</template>
<script>
import zhLocale from "element-ui/lib/locale/lang/zh-CN";
import { provinceData } from "../assets/json/area.js";
// 然后将element-ui中pagination选项改掉'条/页'
zhLocale.el.pagination = {
  pagesize: `条/页`,
  total: `共 {total} 条`,
  goto: "前往第",
  pageClassifier: "页",
};
export default {
  data() {
    return {
      excelBtn: {
        excelBtnLoading: false,
        btnText: "导出当前页Excel",
      },
      examRecords: [],
      loading: false,
      allExamInfo: [],
      queryInfo: {
        pageNo: 1,
        pageSize: 10,
        region: "全部省份",
        score: 60,
        examId: 0,
        number: null,
      },
      total: 0,
      provinceData: provinceData,
    };
  },
  mounted() {
    console.log(this.$route.query);
    if (this.$route.query && this.$route.query.region) {
      console.log("222");
      this.queryInfo.region = this.$route.query.region;
      this.queryInfo.score = this.$route.query.score;
      this.queryInfo.examId = parseInt(this.$route.query.examId);
    }
    console.log(this.queryInfo);
    this.getList();
    this.getAllExamInfo();
    if (this.provinceData[0].label !== "全部省份") {
      this.provinceData.unshift({ value: "", label: "全部省份" });
    }
  },
  methods: {
    getList() {
      console.log(this.queryInfo);
      this.$http
        .get(this.API.getExamRecordS, { params: this.queryInfo })
        .then((res) => {
          console.log(res);
          if (res.data.data) {
            this.examRecords = res.data.data.examRecords;
            this.total = res.data.data.total;
          } else {
            this.examRecords = [];
          }
        });
    },
    //导出excel
    exportExcelClick() {
      if (this.examRecords.length == 0) {
        this.$message.error("暂无考试记录！");
        return;
      }
      this.excelBtn.excelBtnLoading = true;
      this.excelBtn.btnText = "正在导出···";
      let gradeExcelList = [];
      console.log(this.examRecords);
      this.examRecords.forEach((item) => {
        let gradeExcelItem = {
          teachName: item.teachName,
          name: item.name,
          examTime: item.examTime,
          region: item.region,
          phone: item.phone,
          logicScore: item.logicScore,
          teachSore: item.teachSore === null ? "未批阅" : item.teachSore,
        };
        gradeExcelList.push(gradeExcelItem);
      });

      this.$http({
        url: this.API.export,
        method: "post",
        responseType: "blob",
        data: gradeExcelList,
      })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "统计详情.xls");
          document.body.appendChild(link);
          link.click();
          this.excelBtn.excelBtnLoading = false;
          this.excelBtn.btnText = "导出Excel";
        })
        .catch((err) => {
          this.excelBtn.excelBtnLoading = false;
          this.excelBtn.btnText = "导出Excel";
        });
    },
    getAllExamInfo() {
      this.$http.get(this.API.allExamInfo).then((resp) => {
        if (resp.data.code === 200) {
          this.allExamInfo = resp.data.data;
          // console.log(this.allExamInfo);
        }
      });
    },
    examChange() {
      this.getList();
    },
    getUserInfo(value) {
      if (this.queryInfo.number == "") {
        this.queryInfo.number = null;
      }
      this.getList();
    },
    selectProvince(provce) {
      //选择省份
      this.getList();
      if (provce == "全部省份") {
        this.queryInfo.region = "全部省份";
      }
    },
    //分页页面大小改变
    handleSizeChange(val) {
      console.log(val);
      this.queryInfo.pageSize = val;
      this.getList();
    },
    //分页插件的页数
    handleCurrentChange(val) {
      this.queryInfo.pageNo = val;
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
/*表格的头部样式*/
/deep/ .has-gutter tr th {
  background: rgb(242, 243, 244);
  color: rgb(85, 85, 85);
  font-weight: bold;
  line-height: 32px;
}
</style>